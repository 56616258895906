/**
 * Order matters
 */
export enum SmartRecoStep {
  Identification = 'smart_reco_identification',
  Profile = 'smart_reco_profile',
  Weight = 'smart_reco_weight',
  GrowthChart = 'smart_reco_growth_chart',
  Pathologies = 'smart_reco_pathologies',
  NutritionalAssessment = 'smart-reco-nutritional-assessment',
  Recommendation = 'smart_reco_recommendation',
  RecommendationWet = 'smart_reco_recommendation-wet',
  Allowance = 'smart_reco_allowance',
}

export const steps = Object.values(SmartRecoStep);
export const stepsWithoutIdentification = steps.slice(1, steps.length);
